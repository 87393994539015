import React, { useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import { useSelector } from 'react-redux';
import AnimatedBox from "../../components/MotionChild/AnimatedBox";
import Slide from "../../components/Slider/Slide";

const BookingScreen = () => {
    const navigate = useNavigate();
    const handleSelection = useCallback((category, data) => {
        navigate(`/rooms`, { state: { category, data } });
        // eslint-disable-next-line
    }, []);

    const products = useSelector((state) => state.products.products);
    console.log(products);
    const rooms = products.filter(product => product.type === 'talk_room');
    const cars = products.filter(product => product.type === 'car');

    return (
        <Box sx={{ padding: '0.2em' }}>
            {rooms.length > 0 && (
                <>
                    <AnimatedBox delay={0.2}>
                        <Typography variant="h6" gutterBottom>
                            Помещения
                        </Typography>
                    </AnimatedBox>

                    <AnimatedBox delay={0.2}>
                        <Box sx={{ borderRadius: '16px', overflow: 'hidden' }}>
                            <Swiper
                                modules={[Autoplay]}
                                spaceBetween={30}
                                slidesPerView={1}
                                autoplay={{ delay: 3000, disableOnInteraction: false }}
                                loop
                                style={{ borderRadius: '12px 12px 0 0' }}
                            >
                                {rooms.map((room) => (
                                    <SwiperSlide key={room.id}>
                                        <Slide src={room.image_link} alt={room.label} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ marginBottom: '0.5em', borderRadius: '0 0 12px 12px' }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleSelection('talk_room', rooms);
                                }}
                            >
                                Выбрать помещение
                            </Button>
                        </Box>
                    </AnimatedBox>
                </>
            )}

            {cars.length > 0 && (
                <>
                    <AnimatedBox delay={0.2}>
                        <Typography variant="h6" gutterBottom>
                            Автомобили
                        </Typography>
                    </AnimatedBox>
                    <AnimatedBox delay={0.2}>
                        <Box sx={{ borderRadius: '16px', overflow: 'hidden' }}>
                            <Swiper
                                modules={[Autoplay]}
                                spaceBetween={30}
                                slidesPerView={1}
                                autoplay={{ delay: 3000, disableOnInteraction: false }}
                                loop
                                style={{ borderRadius: '12px 12px 0 0' }}
                            >
                                {cars.map((car) => (
                                    <SwiperSlide key={car.id}>
                                        <Slide src={car.image_link} alt={car.label} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ marginBottom: '10px', borderRadius: '0 0 12px 12px' }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleSelection('car', cars);
                                }}
                            >
                                Выбрать автомобиль
                            </Button>
                        </Box>
                    </AnimatedBox>
                </>
            )}
        </Box>
    );
};

export default BookingScreen;
