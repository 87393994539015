import React, { useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Container,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Box,
    Button,
    Modal,
    TextField,
    Snackbar,
} from '@mui/material';
import MapModal from "../../../../components/MapModal/MapModal";
import {editProduct} from "../../../../api/api";
import {fetchAllProducts} from "../../../../service/redux/reducers/productSlice";

const ProductList = () => {
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products.products);
    const [editProductData, setEditProductData] = useState(null);
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [newAddress, setNewAddress] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleEditClick = (product) => {
        setEditProductData({ ...product });
        setNewAddress('');
    };

    const handleSave = async () => {
        try {
            await editProduct(editProductData);
            setEditProductData(null);
            setSnackbarOpen(true);
            dispatch(fetchAllProducts());
        } catch (error) {
            console.error("Ошибка при обновлении продукта:", error);
            alert("Не удалось обновить продукт!");
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleMapModalOpen = () => {
        setIsMapModalOpen(true);
    };

    const handleMapModalClose = (newAddressData) => {
        setIsMapModalOpen(false);
        if (newAddressData) {
            setNewAddress(newAddressData.address);
            setEditProductData((prev) => ({
                ...prev,
                additionally: {
                    ...prev.additionally,
                    coordinates: newAddressData.coordinates,
                    address: newAddressData.address,
                },
            }));
        }
    };

    return (
        <Container maxWidth="md" sx={{ paddingTop: '20px' }}>
            <Grid container spacing={3}>
                {products.map((product) => (
                    <Grid item xs={12} sm={6} md={4} key={product.id}>
                        <Card sx={{ borderRadius: '16px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                            <CardMedia
                                component="img"
                                image={product.image_link}
                                alt={product.label}
                                sx={{ height: 150, objectFit: 'cover' }}
                            />
                            <CardContent>
                                <Typography variant="h6" component="div" gutterBottom>
                                    {product.label}
                                </Typography>
                                {product.additionally && (
                                    <Box>
                                        {product.additionally.address && (
                                            <Typography variant="body2" color="text.secondary">
                                                Адрес: {product.additionally.address}
                                            </Typography>
                                        )}
                                        {product.additionally.capacity && (
                                            <Typography variant="body2" color="text.secondary">
                                                Вместимость: {product.additionally.capacity}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                                <Typography variant="body2" color="text.secondary">
                                    Дополнительно: {product.description || 'Описание отсутствует'}
                                </Typography>
                            </CardContent>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    borderRadius: '0 0 12px 12px',
                                    marginTop: '16px',
                                }}
                                onClick={() => handleEditClick(product)}
                            >
                                Редактировать
                            </Button>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {editProductData && (
                <Modal open={Boolean(editProductData)} onClose={() => setEditProductData(null)}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: '8px',
                            width: '90%',
                            maxWidth: '500px',
                        }}
                    >
                        <Typography variant="h6" mb={2}>
                            Редактировать продукт
                        </Typography>
                        <TextField
                            fullWidth
                            label="Название"
                            value={editProductData.label}
                            onChange={(e) => setEditProductData({ ...editProductData, label: e.target.value })}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Дополнительно"
                            value={editProductData.description}
                            onChange={(e) => setEditProductData({ ...editProductData, description: e.target.value })}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Вместимость"
                            value={editProductData.additionally.capacity || ''}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                    setEditProductData((prev) => ({
                                        ...prev,
                                        additionally: { ...prev.additionally, capacity: value },
                                    }));
                                }
                            }}
                            sx={{ mb: 2 }}
                        />
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={handleMapModalOpen}
                            sx={{ mb: 2 }}
                        >
                            Изменить адрес
                        </Button>
                        {newAddress && (
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                Новый адрес: {newAddress}
                            </Typography>
                        )}
                        <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                            Сохранить
                        </Button>
                    </Box>
                </Modal>
            )}

            {isMapModalOpen && (
                <MapModal
                    open={isMapModalOpen}
                    onClose={handleMapModalClose}
                    currentAddress={editProductData?.additionally?.address || ''}
                    currentCoordinates={editProductData?.additionally?.coordinates || '55.751244,37.618423'}
                />
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={handleSnackbarClose}
                message="Продукт успешно обновлён!"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            />
        </Container>
    );
};

export default ProductList;
