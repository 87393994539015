import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Snackbar,
    Box,
    Alert, // Импортируем Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { addUser } from "../../../../api/api";
import InputMask from 'react-input-mask';
import {roleEmojis} from "../../../../utils/roleEmojis";
import {useSelector} from "react-redux";

const AddUserScreen = () => {
    const { group_id } = useSelector((state) => state.auth);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('+7 ');
    const [privilege, setPrivilege] = useState('global_admin');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();

    const handleAddUser = async () => {
        const missingFields = [];
        if (!firstName.trim()) missingFields.push('Имя');
        if (!lastName.trim()) missingFields.push('Фамилия');
        if (!phone.trim() || phone === '+7 ') missingFields.push('Телефон');

        if (missingFields.length > 0) {
            const message = `Вы не ввели обязательное поле или поля: ${missingFields.join(', ')}`;
            setSnackbarMessage(message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
        const cleanedPhone = phone.replace(/\D/g, '').replace(/^8/, '7');

        const userData = {
            first_name: firstName,
            last_name: lastName,
            phone: cleanedPhone,
            privileges: [privilege],
            groups_ids: [group_id],
        };

        try {
            await addUser(userData);
            setSnackbarMessage('Пользователь успешно добавлен');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setTimeout(() => navigate('/adminpanel'), 2000);
        } catch (error) {
            console.error('Error adding user:', error);
            setSnackbarMessage('Ошибка при добавлении пользователя');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('Text');
        let formattedData = pasteData.trim();
        if (formattedData.startsWith('8')) {
            formattedData = '+7' + formattedData.slice(1);
        }
        formattedData = formattedData.replace(/[^\d+]/g, '');

        setPhone(formattedData);
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '20px' }}>
            <Typography variant="h6" gutterBottom>Добавить пользователя</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
                <TextField
                    label="Имя"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    required
                />
                <TextField
                    label="Фамилия"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    required
                />
                <InputMask
                    mask="+7 999 999 99 99"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onPaste={handlePaste}
                    maskPlaceholder=" "
                    required
                >
                    {() => (
                        <TextField
                            label="Телефон"
                            fullWidth
                            variant="outlined"
                            required
                        />
                    )}
                </InputMask>
                <TextField
                    label="Роль пользователя"
                    value={privilege}
                    onChange={(e) => setPrivilege(e.target.value)}
                    fullWidth
                    select
                    variant="outlined"
                >
                    {Object.entries(roleEmojis).map(([key, { label, emoji }]) => (
                        <MenuItem key={key} value={key}>
                            {emoji} {label}
                        </MenuItem>
                    ))}
                </TextField>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleAddUser}
                    sx={{ mt: 2 }}
                >
                    Добавить пользователя
                </Button>
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                ContentProps={{
                    sx: {
                        backgroundColor: '#fff',
                        color: '#333',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                        padding: '8px 16px',
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    },
                }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AddUserScreen;
