import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import axios from 'axios';

const MapModal = ({
                      open,
                      onClose,
                      currentCoordinates = '55.751244,37.618423',
                      currentAddress = ''
                  }) => {
    const [coordinates, setCoordinates] = useState([55.751244, 37.618423]);
    const [address, setAddress] = useState(currentAddress);

    useEffect(() => {
        if (currentCoordinates) {
            const coords = currentCoordinates.split(',').map(Number);
            setCoordinates(coords);
        }
        setAddress(currentAddress || '');
    }, [currentCoordinates, currentAddress]);

    const fetchAddress = async (lat, lng) => {
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
                params: {
                    lat,
                    lon: lng,
                    format: 'json',
                    addressdetails: 1,
                },
            });
            const data = response.data;
            if (data && data.address) {
                const { road, house_number } = data.address;
                const formattedAddress = `${road || ''}${house_number ? `, ${house_number}` : ''}`;
                setAddress(formattedAddress || 'Адрес не найден');
            } else {
                setAddress('Адрес не найден');
            }
        } catch (error) {
            console.error('Ошибка при обратном геокодировании:', error);
            setAddress('Не удалось определить адрес');
        }
    };

    const handleMarkerDrag = async (e) => {
        const coords = e.get('target').geometry.getCoordinates();
        setCoordinates(coords);
        await fetchAddress(coords[0], coords[1]);
    };

    const handleMapClick = async (e) => {
        const coords = e.get('coords');
        setCoordinates(coords);
        await fetchAddress(coords[0], coords[1]);
    };

    const handleSave = () => {
        onClose({ coordinates: coordinates.join(','), address });
    };

    return (
        <Modal open={open} onClose={() => onClose(null)}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: '8px',
                    width: '90%',
                    maxWidth: '600px',
                }}
            >
                <Typography variant="h6" mb={2}>
                    Укажите местоположение
                </Typography>
                <TextField
                    fullWidth
                    label="Адрес"
                    value={address}
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{ mb: 2 }}
                />
                <YMaps>
                    <Map
                        defaultState={{
                            center: coordinates,
                            zoom: 10,
                        }}
                        width="100%"
                        height="300px"
                        state={{
                            center: coordinates,
                            zoom: 10,
                        }}
                        options={{
                            suppressMapOpenBlock: true,
                            yandexMapDisablePoiInteractivity: true,
                        }}
                        onClick={handleMapClick}
                    >
                        <Placemark
                            geometry={coordinates}
                            options={{ draggable: true }}
                            onDragEnd={handleMarkerDrag}
                        />
                    </Map>
                </YMaps>
                <Button variant="contained" color="primary" fullWidth onClick={handleSave} sx={{ mt: 2 }}>
                    Сохранить
                </Button>
            </Box>
        </Modal>
    );
};

export default MapModal;
