import React, { useEffect, useState, useCallback, useRef } from "react";
import { ThemeProvider, CircularProgress, Box, Container, Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import getTheme from '../theme';
import styles from './App.module.css';
import { GlobalStyles } from "@mui/material";
import { setAuthToken } from "../api/api";
import Lottie from 'lottie-react';
import sadAnimation from '../common/lottieGifs/notInTgAnimation.json';
import error401Animation from '../common/lottieGifs/401Animation.json';
import { fetchAllProducts } from "../service/redux/reducers/productSlice";
import { loginUser } from "../service/redux/reducers/authSlice";
import BookingScreen from "./BookingScreen/BookingScreen";
import RoomSelectionScreen from "./RoomSelectionScreen/RoomSelectionScreen";
import RoomReservationScreen from "./RoomReservationScreen/RoomReservationScreen";
import BookingFormScreen from "./BookingFormScreen/BookingFormScreen";
import MyReservationsScreen from "./MyBooking/MyBooking";
import BottomNavBar from "../components/BottomNavBar/BottomNavBar";
import AdminPanelScreen from "./AdminPanelScreen/AdminPanelScreen";
import AddUserScreen from "./AdminPanelScreen/UsersStack/AddUserScreen/AddUserScreen";
import UsersListScreen from "./AdminPanelScreen/UsersStack/UserListScreen/UserListScreen";
import WhatsNewModal from "./WhatsNewPage/WhatsNewPage";
import MakeReportScreen from "./AdminPanelScreen/MakeReportScreen/MakeReportScreen";
import ProductList from "./AdminPanelScreen/ProductStack/ProductList/ProductList";

const AppContent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [initDataAvailable, setInitDataAvailable] = useState(true);
    const telegramWebApp = window.Telegram.WebApp;
    const dispatch = useDispatch();
    const [isAuthError, setIsAuthError] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [bottomPadding, setBottomPadding] = useState(0);
    const backButtonRef = useRef(telegramWebApp.BackButton);
    const navigateRef = useRef(navigate);
    const user = useSelector((state) => state.auth.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const currentVersion = "1.3";

    useEffect(() => {
        const lastVersionShown = localStorage.getItem('lastVersionShown');

        if (lastVersionShown !== currentVersion) {
            setIsModalOpen(true);
            localStorage.setItem('lastVersionShown', currentVersion);
        }
    }, [currentVersion]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleBackButtonClick = useCallback(() => {
        if (window.history.length > 1) {
            window.history.back();
        } else {
            navigateRef.current('/');
        }
    }, []);

    const calculateBottomPadding = () => {
        const platform = telegramWebApp.platform;
        const viewportHeight = telegramWebApp.viewportHeight;

        if (platform === 'ios') {
            return viewportHeight - window.innerHeight + 40;
        } else if (platform === 'android') {
            return viewportHeight - window.innerHeight;
        } else {
            return 0;
        }
    };

    useEffect(() => {
        if (telegramWebApp && telegramWebApp.expand) {
            telegramWebApp.expand();
        }
        telegramWebApp.ready();
        const initData = telegramWebApp.initData;

        if (initData) {
            dispatch(loginUser(initData))
                .unwrap()
                .then(response => {
                    const token = response.access_token;
                    setAuthToken(token);
                    dispatch(fetchAllProducts());
                })
                .catch(error => {
                    if (error.code === "ERR_BAD_RESPONSE" || error.response?.status === 401) {
                        setIsAuthError(true);
                    } else {
                        console.error('Error:', error);
                    }
                });
        } else {
            setInitDataAvailable(false);
        }

        if (telegramWebApp.viewportHeight) {
            const newPadding = calculateBottomPadding();
            setBottomPadding(newPadding);
        }

        backButtonRef.current.onClick(handleBackButtonClick);

        const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => {
            // eslint-disable-next-line
            backButtonRef.current.offClick(handleBackButtonClick);
            clearTimeout(loadingTimeout);
        };
        // eslint-disable-next-line
    }, [telegramWebApp, dispatch, handleBackButtonClick]);

    useEffect(() => {
        if (location.pathname !== '/') {
            backButtonRef.current.show();
        } else {
            backButtonRef.current.hide();
        }
    }, [location.pathname]);

    if (isLoading) {
        return (
            <div className={styles.loaderContainer}>
                <CircularProgress />
            </div>
        );
    }

    if (!initDataAvailable || isAuthError) {
        return (
            <Container
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    textAlign: 'center'
                }}
            >
                <Box sx={{ mb: 2 }}>
                    <Lottie animationData={isAuthError ? error401Animation : sadAnimation}
                            loop={true} style={{ height: 300, width: 300 }} />
                </Box>
                <Typography variant="body1" className={styles.sadMessage}>
                    {isAuthError
                        ? 'Ошибка авторизации. Проверьте свои данные и попробуйте снова.'
                        : 'Опаньки, похоже это будет работать только в Telegram, или вы не авторизованы в системе.'}
                </Typography>
            </Container>
        );
    }

    return (
        <div className={styles.appContainer}>
            <div className={styles.content}>
                <Routes>
                    <Route path="/" element={<BookingScreen />} />
                    <Route path="/rooms" element={<RoomSelectionScreen />} />
                    <Route path="/rooms/reserve" element={<RoomReservationScreen />} />
                    <Route path="/rooms/booking-form" element={<BookingFormScreen />} />
                    <Route path="/myreservation" element={<MyReservationsScreen />} />
                    <Route path="/adminpanel" element={<AdminPanelScreen />} />
                    <Route path="/adminpanel/users" element={<UsersListScreen />} />
                    <Route path="/adminpanel/add-user" element={<AddUserScreen />} />
                    <Route path="/adminpanel/products" element={<ProductList />} />
                    <Route path="/adminpanel/report" element={<MakeReportScreen />} />
                </Routes>
            </div>
            <BottomNavBar bottomPadding={bottomPadding} privileges={user?.privileges || []} />
            <WhatsNewModal open={isModalOpen} handleClose={handleCloseModal} currentVersion={currentVersion} />
        </div>
    );
};

const App = () => {
    return (
        <ThemeProvider theme={getTheme('light')}>
            <GlobalStyles styles={{
                body: {
                    backgroundColor: '#FFFFFFFF !important',
                    color: '#1D3557 !important',
                }
            }} />
            <Router>
                <AppContent />
            </Router>
        </ThemeProvider>
    );
};

export default App;
